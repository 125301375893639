import React, { useEffect, useState } from 'react';
import SurveyCake from 'pages/surveyCake';
import { fetchAndSaveAuthToken } from 'services/user.service';
import { setupLiff } from 'src/lib/liff';
import { getUserInfo } from '../../../services/user.service';
import { getURLSearchParamByKey } from 'src/utils';
import { t } from 'src/i18n/config';

export default function Home() {
  const [message, setMessage] = useState({});
  const [token, setToken] = useState();
  const [user, setUser] = useState();

  useEffect(() => {
    setupLiff()
      .then(async (res) => {
        setMessage(res);
        const { userId, liffId } = res;
        if (userId && liffId) {
          const t = await fetchAndSaveAuthToken({ userId, liffId });
          // 拿不到就算了，不影響使用
          const user = await getUserInfo().catch((err) => {
            console.log('取得使用者資訊失敗', err.message);
            return {};
          });
          if (
            user.patients &&
            user.patients.length !== 0 &&
            user.patients.find((u) => u.isSelf)
          ) {
            const self = user.patients.find((u) => u.isSelf);
            setUser({ ...self, ...user });
          } else {
            setUser(user);
          }
          setToken(t);
          return;
        }
        alert(
          `${t('userNotFound')}${userId}, ${liffId} ${window.location.href}`
        );
      })
      .catch(async (err) => {
        setMessage(err);
      });
  }, []);
  if (token) {
    return (
      <SurveyCake
        id={getURLSearchParamByKey('clientID')}
        userId={message.userId}
        currentLineUser={{
          phone: user && user.Phone !== '' ? user.Phone : undefined,
          lineID: message.userId,
          name: user && user.name !== '' ? user.name : undefined,
          idNum: user && user.idNum !== '' ? user.idNum : undefined,
        }}
        displayName={message.lineProfile.displayName}
        lineProfilePhone={message.lineProfilePhone}
      ></SurveyCake>
    );
  }
  return null;
}
